define("ember-tooltips/templates/components/ember-tooltip-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ktzk7lgV",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"ember-wormhole\",null,[[\"to\",\"renderInPlace\"],[[25,[\"wormholeId\"]],[25,[\"_awaitingTooltipElementRendered\"]]]],{\"statements\":[[0,\"  \"],[7,\"div\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"_shouldRenderContent\"]]],null,{\"statements\":[[4,\"if\",[[26,1]],null,{\"statements\":[[0,\"        \"],[15,1,[[24,0,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[23,\"text\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-tooltips/templates/components/ember-tooltip-base.hbs"
    }
  });

  _exports.default = _default;
});